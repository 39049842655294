<template>
  <div>

    <v-toolbar color="white" flat>
      <v-toolbar-title>Payroll Archives</v-toolbar-title>
      <v-divider
              class="mx-2"
              inset
              vertical
      ></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
            :headers="headers"
            :items="items"
            dense
    >
      <template v-slot:body="{ items }">
        <tbody>
        <tr :key="item.id" v-for="item in items">
          <td class="text-center">{{ item.start }}</td>
          <td class="text-center">{{ item.end }}</td>
          <td class="text-center">{{ item.total_empl }}</td>
          <td class="text-center">{{ financialI(item.total_hours) }}</td>
          <td class="text-center">{{ financialI(item.total_pay) }}</td>
          <td class="text-center">{{ prettyDT(item.appv_date) }}</td>
          <td class="text-center">{{ item.appv_name }}</td>
          <td class="justify-center layout">
            <v-icon @click="view(item)" class="mr-2" small>
              mdi-format-list-bulleted
            </v-icon>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-row>
      <v-col>
        <v-btn @click="refresh" class="white--text mx-6" color="primary">
          <v-icon dark left>mdi-refresh</v-icon>
          Refresh
        </v-btn>
      </v-col>
      <v-col>
        <v-alert :value="(errorMessage!='')" outlined type="error">
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>

    <busy :message="busyMessage"></busy>

  </div>
</template>

<script>
  import Busy from './Busy'
  import axios from 'axios'
  import { financialI, prettyDT } from './utils'
  import { format, parseISO } from 'date-fns'

  export default {
    name: "Archives",
    components: { Busy },

    created () {
      console.log('Created:', this.$options.name)
    },
    mounted () {
      console.log('Mounted:', this.$options.name)
      this.refresh()
    },
    beforeDestroy () {
      console.log('Destroy:', this.$options.name)
      this.busyMessage = ''
      this.errorMessage = ''
    },

    methods: {
      financialI,
      format,
      parseISO,
      prettyDT,

      refresh () {
        console.log('Refresh archives')
        if (!this.$store.getters.loggedIn) {
          return
        }
        var url = '/payroll/archives'
        this.busyMessage = 'Loading...'
        axios.get(url)
            .then(res => {
              this.items = res.data
              if (this.items == null) {
                this.items = []
              }
              console.log('Archives:', this.items)
              this.busyMessage = ''
            })
            .catch(error => {
              console.log(error.toString())
              this.busyMessage = ''
              this.showAlert('Error unable to load archives: ' + error.toString())
            })
      },
      view (item) {
        console.log('View pay period:', item.id, item.start)
        this.$router.push('/admin/payroll/' + item.id)
      },
      showAlert (msg) {
        this.errorMessage = msg
        setTimeout(() => (this.errorMessage = ''), 5000)
      }
    },

    data () {
      return {
        busyMessage: '',
        errorMessage: '',
        headers: [
          { text: 'Start', align: 'center', value: 'start' },
          { text: 'End', align: 'center', value: 'end' },
          { text: 'Employees', align: 'center', value: 'total_empl' },
          { text: 'Hours', align: 'center', value: 'total_hours' },
          { text: 'Pay $', align: 'center', value: 'total_pay' },
          { text: 'Approved', align: 'center', value: 'appv_date' },
          { text: 'By', align: 'center', value: 'appv_name' },
          { text: 'View', align: 'center', value: 'edit', sortable: false }
        ],
        items: []
      }
    }

  }
</script>

<style scoped>

</style>
